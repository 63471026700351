import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const SectionWrapper = styled.section`
  background-color: ${grayscale[100]};
  padding: 24px 0;
  display: flex;
  justify-content: center;

  @media ${device.tablet}{
    padding: 48px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  *{
    font-family: Inter, sans-serif;
    color: ${grayscale[500]};
  }
  .container{
    padding: 0;
    max-width: none;
  }

  .content_wrapper{
    padding: 0;
    
    &__title{
      font-family: Citrina, sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      margin: 0;
      padding: 0 24px;

      @media ${device.tablet}{
        margin: 0 auto;
        width: fit-content;
        margin-bottom: 10px;
      }

      @media ${device.desktopLG}{
        font-size: 28px;
        line-height: 33.6px;
      }

      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 57.6px;
      }

    }
    &__carousel{
      @media ${device.tablet}{
        padding: 0 12px;
      }

      @media ${device.desktopLG}{
        padding: 0px;
        display: flex;
        justify-content: center;
      }

      @media ${device.desktopXXL}{
      }

      &__item{
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        padding: 0 24px;
        height: 100%;

        @media ${device.desktopXXL}{
          padding: 0;
        }

        &__instructions{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;

          &__index{
            background-color: black;
            border-radius: 50%;
            width: 20px;
            height: 20px;;

            align-content: center;
            text-align: center;
            color: white;
            font-weight: 700;
            font-size: 9.33px;
            line-height: 11.29px;
            margin: 0 12px 0 0;

            @media ${device.desktopLG}{
              font-size: 12px;
              line-height: 16px;
            }
          }
          &__text{
            margin: 0;
            width: 90%;
            font-weight: 400;
            font-size: 16px;
            line-height: 19.36px;

            @media ${device.desktopXXL}{
              width: 75%;
            }
          }
          &__image{
            display: flex;
            margin: 0 auto;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 500px;

            @media ${device.desktopXXL}{
              max-height: 610px;
            }
          }
        }
      }
    }
    &__make_transfer{
      margin: 32px auto 0 auto;
      color: white;
      width: 86%;
      display: flex;
      background-color: ${primary[500]};
    
      @media ${device.tablet}{
        width: 50%;
        margin: 40px auto 0 auto;
      }

      @media ${device.desktopLG}{
        width: 40%;
        margin-top: -10px;
      }

      @media ${device.desktopXXL}{
        width: 30%;
      }
    
    }
  }
`
