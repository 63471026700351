import React from 'react'
import * as S from './styles'
import { Button } from '@interco/inter-ui/components/Button'
import exchangeUsesCases from './exchangeUsesCases.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

type QuandoUsarOsServicosProps = {
  setDatalayer: Function;
  setIsModalOpen: Function;
}

const QuandoUsarOsServicos = ({ setDatalayer, setIsModalOpen }: QuandoUsarOsServicosProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const width = useWidth()

  const simulateButton = domReady && (
    width >= 768
    ? (
      <Button
        className='content_wrapper__simulate'
        fullWidth
        onClick={() => {
          setIsModalOpen(true)
          setDatalayer({
            section: 'dobra_07',
            section_name: 'Quando usar os serviços de câmbio do Inter?',
            element_action: 'click button',
            element_name: 'Quero simular',
          })
        }}
      >
        Quero simular
      </Button>
    ) : (
      <Button
        as='a'
        href='https://intergo.app/53a0ce12'
        className='content_wrapper__simulate'
        fullWidth
        onClick={() => sendDatalayerEvent({
          section: 'dobra_07',
          section_name: 'Quando usar os serviços de câmbio do Inter?',
          element_action: 'click button',
          element_name: 'Quero simular',
          redirect_url: 'https://intergo.app/53a0ce12',
        })}
      >
        Quero simular
      </Button>
    )
  )

  return (
    <S.SectionWrapper>
      <div className='row container'>
        <picture
          className='image_container col-12 col-md-6 col-lg-5'
        >
          <source
            srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra7-web/image.webp'
            media='(min-width: 1440px)'
          />
          <source
            srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra7-1024/image.webp'
            media='(min-width: 768px)'
          />
          <img
            className='image_container__source'
            src=' https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra7-360/image.webp'
            alt=' mulher em aeroporto'
          />
        </picture>
        <div className='content_wrapper col-12 col-md-6 col-lg-5'>
          <h2 className='content_wrapper__title'>
            Quando usar os serviços de câmbio do Inter?
          </h2>
          <ul className='content_wrapper__exchange_uses'>
            {exchangeUsesCases.map((text: string, index: number) => (
              <li
                className='content_wrapper__exchange_uses__cases'
                key={`case${index}`}
              >
                <div className='content_wrapper__exchange_uses__cases__bullet' />
                <p
                  className='content_wrapper__exchange_uses__cases__text'
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </li>
            ))}
          </ul>
          {simulateButton}
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default QuandoUsarOsServicos
