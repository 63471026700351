import React from 'react'
import * as S from './styles'
import blogCardsInfo from './blogCardsInfo.json'
import BlogCard from 'src/components/BlogCard/_BlogCard'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type blogCardsInfoProps = {
  id: string;
  category: string;
  title: string;
  description: string;
  date: string;
  link: string;
  image: string;
}

const TudoSobreBeneficios = () => {
  const [ sendDataLayer ] = useDataLayer()
  return (
    <S.SectionWrapper>
      <div className='container row'>
        <div className='content_wrapper col-12'>
          <h2 className='content_wrapper__title'>Tudo sobre os benefícios Inter</h2>
          <article className='content_wrapper__blog_container'>
            {blogCardsInfo.map((card: blogCardsInfoProps) => (
              <BlogCard
                key={card.id}
                card={card}
                showImage
                onCardClick={() => sendDataLayer({
                  section: 'dobra_09',
                  section_name: 'Tudo sobre os benefícios Inter',
                  element_action: 'click button',
                  element_name: card.title,
                  redirect_url: card.link,
                })}
              />
            ))}
          </article>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default TudoSobreBeneficios
