import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { sand } from "src/styles/newColors"
import styled from "styled-components"

export const ContentWrapper = styled.section`
  padding: 24px 0;
  background-color: ${sand};

  @media ${device.tablet}{
    padding: 48px 0
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  *{
    font-family: Inter, sans-serif;
    color: ${grayscale[500]};
  }

  h2{
    font-family: Citrina, sans-serif;
  }

  .container{
    padding: 0;
  }

  .row{
    margin: 0 24px;

    @media ${device.tablet}{
      margin: 0 12px;
    }

    @media ${device.desktopLG}{
      display: flex;
      justify-content: space-evenly;
    }
  }

`

export const ImageWrapper = styled.picture`
  padding: 0;
  margin: 24px 0;

  width: 100%;
  display: flex;
  justify-content: center;

  @media ${device.tablet}{
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }

  @media ${device.desktopLG} {
    justify-content: center;
    align-items: center; 
  }

  @media ${device.desktopXXL}{
    align-items: flex-start;
  }

  .image_wrapper__image{
    padding-left: 14px;
    
    @media ${device.tablet}{
      max-width: 67%;
      height: max-content;
      padding: 0;
    }

    @media ${device.desktopLG}{
      max-width: 100%;
    }

  }
`

export const InformationsDiv = styled.div`
  padding: 0;
  margin: 0;

  @media ${device.tablet}{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .informations_div__title{
    font-weight: 500;
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 24px;

    @media ${device.desktopLG}{
      font-size: 40px;
      line-height: 44px;
      width: 65%;
    }

    @media ${device.desktopXXL}{
      font-size: 48px;
      line-height: 52.8px;
      margin-bottom: 18px;
    }
  }
  .informations_div__cards_wrapper{

    @media ${device.tablet}{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
    }

    &__card{
      background-color: white;
      padding: 24px 16px;
      border-radius: 16px;
      margin: 12px 0;
      
      @media ${device.tablet}{
        margin: 5px;
        width: 100%;
        max-width: 46%;
      }

      @media ${device.desktopLG}{
        padding: 40px 32px;
        margin: 4px;
      }

      @media ${device.desktopXXL}{
        margin: 8px;
        border-radius: 32px;
        max-width: 43%;
      }

      &__title{
        font-weight: 600;
        font-size: 16px;
        line-height: 19.36px;

        @media ${device.desktopXXL} {
          font-size: 18px;
          line-height: 21.78px;
          margin-bottom: 8px
        }
      }
      
      &__text{
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        margin: 0;
      }
    }
  }
`
