import React from 'react'
import * as S from './styles'
import cardsInfo from './cardsInfo.json'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

type cardProps = {
  title: string;
  text: string;
}

const CambioComCondicoesExclusivas = () => {
  const domReady = useDomReady()
  const width = useWidth()

  const Image = domReady && (
    <S.ImageWrapper className='col-12 col-md-6 col-lg-4 col-xl-5'>
      <source
        srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra2-web/image.webp'
        media='(min-width: 1024px)'
      />
      <source
        srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra2-768/image.webp'
        media='(min-width: 768px)'
      />
      <img
        className='image_wrapper__image'
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra2-360/image.webp'
        alt='Homem com celular na mão acessando o Super App do Inter'
        loading='lazy'
      />
    </S.ImageWrapper>
  )

  return (
    <S.ContentWrapper>
      <div className='container'>
        <div className='row'>

          <S.InformationsDiv className='col-12 col-md-6 col-lg-7'>
            <h2 className='informations_div__title'>
              Câmbio com  condições exclusivas
            </h2>
            { width < 768 && Image }
            <article className='informations_div__cards_wrapper'>
              {cardsInfo.map((item: cardProps, index: number) => (
                <div
                  className='informations_div__cards_wrapper__card'
                  key={`card${index}`}
                >
                  <h3 className='informations_div__cards_wrapper__card__title'>
                    {item.title}
                  </h3>
                  <p
                    className='informations_div__cards_wrapper__card__text'
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              ))}
            </article>
          </S.InformationsDiv>
          {width >= 768 && Image}
        </div>
      </div>
    </S.ContentWrapper>
  )
}

export default CambioComCondicoesExclusivas
