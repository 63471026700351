import React from 'react'
import * as S from './styles'
import cardsInfo from './cardsInfo.json'
import { Button } from '@interco/inter-ui/components/Button'
import AccentHeaderCard from '../../../../components/AccentHeaderCard/_index'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { orange } from 'src/styles/colors'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type itemProps = {
  title: string;
  description: string;
}

const ContaGlobalParaUsar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper>
      <div className='row container'>
        <div className='col-12 content_wrapper'>
          <h2 className='content_wrapper__title'>
            Conta global para usar em qualquer lugar do mundo
          </h2>
          <p className='content_wrapper__description'>
            Que tal ter uma conta global gratuita direto pelo Super App? Com a Global Account Inter você compra dólares com poucos cliques, e aproveita uma série de benefícios!
          </p>
          <DefaultCarousel
            containerClass='content_wrapper__benefits'
            customDotColor={orange.extra}
            sm={{ items: 1, partialVisibilityGutter: 130 }}
            md={{ items: 3, partialVisibilityGutter: 30 }}
            lg={{ items: 5, partialVisibilityGutter: 0 }}
          >
            {cardsInfo.map((item: itemProps, index: number) => (
              <AccentHeaderCard
                key={`card${index}`}
                className='content_wrapper__benefits__card'
                title={item.title}
                titleClass='content_wrapper__benefits__card__title'
                description={item.description}
                descriptionClass='content_wrapper__benefits__card__description'
                descriptionBackgroundColor='sand'
              />
            ))}
          </DefaultCarousel>
          <Button
            as='a'
            href='https://intergo.app/b766cf1f'
            fullWidth
            className='content_wrapper__open_account'
            onClick={() => sendDatalayerEvent({
              section: 'dobra_06',
              section_name: 'Conta global para usar em qualquer lugar do mundo',
              element_action: 'click button',
              element_name: 'Abrir Conta Internacional',
              redirect_url: 'https://intergo.app/b766cf1f',
            })}
          >
            Abrir Conta Internacional
          </Button>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default ContaGlobalParaUsar
