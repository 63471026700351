import React from 'react'
import * as S from './styles'
import carouselInfos from './carouselInfos.json'
import { Button } from '@interco/inter-ui/components/Button'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { primary } from 'src/styles/newColors'

type carouselItemProps = {
  text: string;
  image: string;
  altText: string;
}

const EnviarRemessasInternacionais = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper>
      <div className='row container'>
        <div className='content_wrapper col-12'>
          <h2 className='content_wrapper__title'>
            Enviar remessas internacionais é mais simples com o Inter
          </h2>
          <DefaultCarousel
            sm={{ items: 1, partialVisibilityGutter: 60 }}
            md={{ items: 2, partialVisibilityGutter: 90 }}
            lg={{ items: 3, partialVisibilityGutter: 0 }}
            xl={{ items: 3, partialVisibilityGutter: 30 }}
            customDotColor={primary[500]}
            containerClass='content_wrapper__carousel'
          >
            {carouselInfos.map((item: carouselItemProps, index: number) => (
              <div
                className='content_wrapper__carousel__item'
                key={`item${index}`}
              >
                <div className='content_wrapper__carousel__item__instructions'>
                  <p className='content_wrapper__carousel__item__instructions__index'>
                    {index + 1}
                  </p>
                  <p
                    className='content_wrapper__carousel__item__instructions__text'
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
                <img
                  className='content_wrapper__carousel__item__instructions__image'
                  src={item.image}
                  alt={item.altText}
                />
              </div>
            ))}
          </DefaultCarousel>
          <Button
            as='a'
            href='https://intergo.app/53a0ce12'
            className='content_wrapper__make_transfer'
            fullWidth
            variant='primary'
            onClick={() => sendDatalayerEvent({
              section: 'dobra_03',
              section_name: 'Enviar remessas internacionais é mais simples com o Inter',
              element_action: 'click button',
              element_name: 'Fazer transferência internacional',
              redirect_url: 'https://intergo.app/53a0ce12',
            })}
          >
            Fazer transferência internacional
          </Button>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default EnviarRemessasInternacionais
