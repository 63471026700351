import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const SectionWrapper = styled.section`
  background-color: ${primary[500]};
  padding: 24px 0;
  display: flex;
  justify-content: center;

  @media ${device.tablet}{
    padding: 48px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra5-768/image.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media ${device.desktopLG}{
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra-1024/image.webp);
  }
  
  @media ${device.desktopXXL}{
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra/image.webp);
    padding: 96px 0 142px 0;
  }

  *{
    font-family: Inter, sans-serif;
    color: ${grayscale[500]};
  }
  
  h2{
    font-family: Citrina, sans-serif;
  }
  
  .content_wrapper{
    background-color: white;
    border-radius: 16px;
    padding: 32px 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.tablet}{
      padding: 32px 24px;
    }

    @media ${device.desktopLG}{
      padding: 48px 32px;
    }
    
    &__image{
      width: auto;
      max-height: 160px;

      @media ${device.tablet} {
        display: none;
      }
    }

    &__texts_wrapper{
      padding: 24px 15px 0 15px;

      @media ${device.tablet}{
        padding: 0;
      }

      &__title{
        font-weight: 500;
        font-size: 24px;
        line-height: 28.8px;
        margin-bottom: 16px;

        @media ${device.desktopLG}{
          font-size: 28px;
          line-height: 33.6px;
          margin-bottom: 24px;
        }
        
        @media ${device.desktopXXL}{
          font-size: 48px;
          line-height: 57.6px;
        }
      }
      &__description{
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        margin-bottom: 16px;

        @media ${device.desktopLG}{
          margin-bottom: 24px ;
        }

        @media ${device.desktopXXL}{
          font-size: 18px;
          line-height: 21.78px;
        }
      }
      &__advantages{
        padding: 0 2px;


        &__benefit{
          margin: 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.36px;    
          font-family: Inter, sans-serif;
          color: ${grayscale[500]};

          @media ${device.desktopLG}{
            margin: 24px 16px;
          }

          @media ${device.desktopXXL}{
            margin: 24px 0 24px 16px;
          }
        }
      }
      &__buy_dollars{
        color: white;
        margin: 0;
        background-color: ${primary[500]};
      }
    }
  }
`
