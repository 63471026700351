import { device } from "src/styles/breakpoints"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const ContentWrapper = styled.section`
  background: ${primary[500]};
  padding: 40px 0;
  overflow-x: hidden;

  @media ${device.desktopLG}{
    padding: 80px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  *{
    font-family: Inter, sans-serif;
  }
  h1{
    font-family: Citrina, sans-serif;
  }

  .container{
    @media ${device.tablet}{
      padding: 0;
      display: flex;
    }

    @media ${device.tablet}{
      justify-content: space-between;
    }
  }

  .visual_element{
    display: none;

    @media ${device.tablet}{
      display: flex;
    }

    &__image{
      position: absolute;
      width: 361px;
      height: 181px;
      top: 25%;
      right: 0;

      @media ${device.desktopLG}{
        right: -25%;    
        width: 532px;
        height: 266px;
      }

      @media ${device.desktopXXL}{
        width: 411px;
        height: 548px;
        right: 15%;
        top: 0%;
      }
    }
  }
`

export const InformationsDiv = styled.div`
  padding: 0 0 16px 0;
  margin: 0;

  @media ${device.tablet}{
    padding: 0 16px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .informations_div__title{
    color: white;
    font-weight: 500;
    font-size: 28px;
    line-height: 33.6px;
    margin-bottom: 16px;

    @media ${device.desktopLG}{
      font-size: 48px;
      line-height: 52.8px;
      margin-bottom: 0;
    }

    @media ${device.desktopXXL}{
      font-size: 56px;
      line-height: 67.2px;
    }
  }

  .informations_div__subtitle{
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    margin-bottom: 16px;

    @media ${device.tablet}{
      font-size: 18px;
      line-height: 21.78px;
      margin: 24px 0;
    }
  }

  .informations_div__buttons--oppen_account{
    background-color: white;
    color: ${primary[500]};
    margin-bottom: 8px;

    &:hover{
      color: white;
      background-color: ${primary[400]};
    }
  }

  .informations_div__buttons--already_client{
    background-color: ${primary[500]};
    color: white;
    border-color: white;
    text-align: center;

    &:hover{
      color: ${primary[500]};
    }
  }
`

export const CalculatorDiv = styled.div`
  padding: 16px 0 0 0;
  margin: 0;

  @media ${device.tablet}{
    padding: 0 24px;
  }

  @media ${device.desktopLG}{
    padding: 0 0 0 48px;
  }

  @media ${device.desktopXXL}{
    padding: 0;
    display: flex;
    align-items: center;
  }

  & div:last-child{
    @media ${device.desktopLG} {
      width: 100%;
    }

    @media ${device.desktopXXL}{
      height: fit-content;
    }
  }
`
