
import { brand, grayscale } from "src/styles/colors"
import { sand } from "src/styles/newColors"
import styled from "styled-components"

export const Card = styled.article`
  .card__title{
    border-radius: 16px 16px 0 0;
    background-color: ${brand.claryOrange};
    margin: 0;
    padding: 16px 12px;
    color: white;
    font-family: Citrina, sans-serif;
    text-align: center;
  }
  .card__description{
    font-family: Inter, sans-serif;
    border-radius: 0 0 16px 16px;
    text-align: center;
    margin: 0;
    padding: 16px 12px;
    color: ${grayscale[500]};

    &--background-white{
      background-color: white;
    }
    &--background-sand{
      background-color: ${sand};
    }
  }
`
