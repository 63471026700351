import { device } from "src/styles/breakpoints"
import { brand, grayscale } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const SectionWrapper = styled.section`
  background-color: ${brand.sand};
  padding: 24px 0;

  @media ${device.tablet} {
    padding: 48px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  .container{
    @media ${device.tablet}{
      margin: 0 auto;
      padding: 0;
    }

    @media ${device.desktopLG}{
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
    }

    @media ${device.desktopXXL}{
      align-items: center;
    }
  }
  .row{
    margin: 0 auto;
  }

  .image_container{
    padding: 0;
    margin-bottom: 32px;

    @media ${device.tablet}{
      margin: 0;
      padding-right: 24px
    }

    @media ${device.desktopLG}{
      padding-right: 28px;
    }

    @media ${device.desktopXXL}{
      padding: 0;
      height: fit-content;
      width: fit-content;
    }

    &__source{
      width: 100%;
    
      @media ${device.desktopXXL}{
        width: 98%;
      }
    }
  }
  .content_wrapper{
    padding: 0;

    @media ${device.tablet}{
      padding-left: 8px;
    }

    &__title{
      margin: 0;

      font-family: Citrina, sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      color: ${grayscale[500]};

      @media ${device.tablet} {
        margin-bottom: 32px;
      }

      @media ${device.desktopLG}{
        font-size: 28px;
        line-height: 33.6px;
      }

      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 57.6px;
      }
    }
    &__exchange_uses{
      &__cases{
        margin: 16px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__bullet{
          width: 6px;
          height: 6px;
          background-color: ${grayscale[400]};
          border-radius: 50px;
          margin: 0 20px 0 6px;
        }
        &__text{
          font-family: Inter, sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.36px;
          color: ${grayscale[400]};
          margin: 0;
          width: 100%;

          @media ${device.desktopLG}{
            font-size: 18px;
            line-height: 21.78px;
          }
        }
      }
    }
    &__simulate{
      background-color: ${primary[500]};

      @media ${device.tablet}{
        margin-top: 16px;
      }
    }

  }
`
