import React, { useEffect } from 'react'
import * as S from './styles'
import { Button } from '@interco/inter-ui/components/Button'
import { CalculatorUS } from 'src/components/USCalculatorForm/status/sent/CalculatorUS'
import { ICalculatorUS } from 'src/components/USCalculatorForm/status/sent/CalculatorUS/types/types'
import { Theme, initTheme } from '@interco/inter-ui'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import { orange } from 'src/styles/colors'

type HeroCambioNoInterParams = {
  setIsModalOpen: Function;
  setDatalayer: Function;
}

const HeroCambioNoInter = ({ setIsModalOpen, setDatalayer }: HeroCambioNoInterParams) => {
  const domReady = useDomReady()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const alreadyClientLink = 'https://bancointer.go.link/cambio/home?adj_t=1ednntu5&adj_adgroup=crossborder&adj_creative=globalaccount_cambio&adj_fallback=https%3A%2F%2Finter.co%2Fabrir-conta-inter%2F%3Futm_source%3Dsite%26utm_medium%3Dlandingpage%26utm_campaign%3Dsite_crossborder_globalaccount_cambio&adj_redirect_macos=https%3A%2F%2Finter.co%2Fabrir-conta-inter%2F%3Futm_source%3Dsite%26utm_medium%3Dlandingpage%26utm_campaign%3Dsite_crossborder_globalaccount_cambio'
  const openAccountLink = 'https://intergo.app/53a0ce12'

  const OppenAccountButton = domReady && (
    width >= 768
    ? (
      <Button
        className='informations_div__buttons--oppen_account'
        fullWidth
        variant='primary'
        onClick={() => {
          setIsModalOpen(true)
          setDatalayer({
            section: 'hero',
            section_name: 'Câmbio no Inter: transferências internacionais com poucos cliques',
            element_action: 'click button',
            element_name: 'Abra sua conta',
          })
        }}
      >
        Abra sua conta
      </Button>
    ) : (
      <Button
        className='informations_div__buttons--oppen_account'
        fullWidth
        as='a'
        href={openAccountLink}
        variant='primary'
        onClick={() => {
          sendDatalayerEvent({
            section: 'hero',
            section_name: 'Câmbio no Inter: transferências internacionais com poucos cliques',
            element_action: 'click button',
            element_name: 'Abra sua conta',
            redirect_url: openAccountLink,
          })
        }}
      >
        Abra sua conta
      </Button>
    )
  )

  const AlreadyClientButton = domReady &&
    width >= 768
    ? (
      <Button
        className='informations_div__buttons--already_client'
        fullWidth
        variant='secondary'
        onClick={() => {
          setIsModalOpen(true)
          setDatalayer({
            section: 'hero',
            section_name: 'Câmbio no  Inter: transferências internacionais com poucos cliques',
            element_action: 'click button',
            element_name: 'Já sou cliente e quero fazer uma transferência',
          })
        }}
      >
        Já sou cliente e quero fazer uma transferência
      </Button>
    ) : (
      <Button
        className='informations_div__buttons--already_client'
        fullWidth
        variant='secondary'
        as='a'
        href={alreadyClientLink}
        onClick={() => sendDatalayerEvent({
          section: 'hero',
          section_name: 'Câmbio no  Inter: transferências internacionais com poucos cliques',
          element_action: 'click button',
          element_name: 'Já sou cliente e quero fazer uma transferência',
          redirect_url: alreadyClientLink,
        })}
      >
        Já sou cliente e quero fazer uma transferência
      </Button>
    )

  const customLink = () => (
    width < 768
    ? (
      <a
        href={openAccountLink}
        className='fw-700 fs-12 text-underline'
        style={{ textDecoration: 'underline', cursor: 'pointer', letterSpacing: '0' }}
        onClick={() => sendDatalayerEvent({
          section: 'hero',
          section_name: 'Câmbio no  Inter: transferências internacionais com poucos cliques',
          element_action: 'click button',
          element_name: 'Já sou correntista,quero simular no app',
          redirect_url: openAccountLink,
        })}
      >
        Já sou correntista,quero simular no app
      </a>
    ) : (
      <p
        className='fw-700 fs-12 text-underline'
        style={{ textDecoration: 'underline', cursor: 'pointer', letterSpacing: '0', color: orange.extra }}
        onClick={() => {
          setIsModalOpen(true)
          setDatalayer({
            section: 'hero',
            section_name: 'Câmbio no  Inter: transferências internacionais com poucos cliques',
            element_action: 'click button',
            element_name: 'Já sou correntista,quero simular no app',
          })
        }}
      >
        Já sou correntista,quero simular no app
      </p>
    )
  )

  const calculatorUSInfo: Pick<ICalculatorUS, 'textData'> = {
    textData: {
      title: 'Você envia',
      secondaryTitle: 'Você recebe',
      exchangeText: 'Câmbio',
      description: [
        '* IOF e outros impostos podem incidir de acordo com o seu perfil de operação.',
        '** Os valores simulados tem validade de 5 minutos.',
      ],
      button: 'string',
      CustomButtom: () => <></>,
      CustomLink: customLink,
    },
  }

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <S.ContentWrapper>
      <div className='container'>

        <S.InformationsDiv className='row col-12 col-md-6 col-lg-5'>
          <h1 className='informations_div__title'>
            Câmbio no  Inter: transferências internacionais com poucos cliques
          </h1>
          <p className='informations_div__subtitle'>
            Taxas reduzidas para você e para a sua empresa. Realize remessas internacionais, pagamentos e transações em até 6 moedas.
          </p>
          {OppenAccountButton}
          {AlreadyClientButton}
        </S.InformationsDiv>
        <S.CalculatorDiv className='row col-12 col-md-6 col-lg-5 col-xl-6'>
          <picture className='visual_element'>
            <source srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-hero-1440/image.webp' media='(min-width: 768px)' />
            <img loading='lazy' src='' alt='' className='visual_element__image' />
          </picture>
          <CalculatorUS
            textData={calculatorUSInfo.textData}
            filterBrazil
          >
            <CalculatorUS.Title />
            <CalculatorUS.Sender />
            <CalculatorUS.SecondaryTitle />
            <CalculatorUS.Destination />
            <CalculatorUS.Description />
            <CalculatorUS.Link />
            <CalculatorUS.SecondaryExchangeTaxes />
          </CalculatorUS>
        </S.CalculatorDiv>
      </div>
    </S.ContentWrapper>
  )
}

export default HeroCambioNoInter
