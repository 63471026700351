import React from 'react'
import * as S from './styles'
import conditionsInfos from './conditionsInfos.json'
import AccentHeaderCard from 'src/components/AccentHeaderCard/_index'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

type conditionsInfosProps = {
  title: string;
  description: string;
}

const EconomizeComOCambio = () => {
  const domReady = useDomReady()
  const width = useWidth()

  return (
    <S.SectionWrapper>
      <div className='container row'>
        <main className='main col-12'>
          <h2 className='main__title'>
            Economize com o câmbio do Inter
          </h2>
          <div className='main__content'>
            <article className='main__content__conditions'>
              {conditionsInfos.map((item: conditionsInfosProps, index: number) => (
                <AccentHeaderCard
                  className={`main__content__conditions__card main__content__conditions__card--${index}`}
                  titleClass='main__content__conditions__card__title'
                  descriptionClass='main__content__conditions__card__description'
                  descriptionBackgroundColor='sand'
                  key={`card${index}`}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </article>
            { width >= 768 && domReady && (
              <picture className='main__content__image_container'>
                <source
                  srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra8-web/image.webp'
                  media='(min-width: 1024px)'
                />
                <source
                  srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra8-768/image.webp'
                  media='(min-width: 768px)'
                />
                <img
                  loading='lazy'
                  src=''
                  alt='mulher com roupa laranja usando celular'
                  className='main__content__image_container__image'
                />
              </picture>
            )}
          </div>

        </main>
      </div>
    </S.SectionWrapper>
  )
}

export default EconomizeComOCambio
