import React from 'react'
import * as S from './styles'
import { Button } from '@interco/inter-ui/components/Button'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const CompreDolar = () => {
  const domReady = useDomReady()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper aria-label='Homem deitado usando celular'>
      <div className='row container'>
        <div className='content_wrapper col-12 col-md-7 col-lg-6 col-xl-5'>
          {width < 768 && domReady && (
            <img
              className='content_wrapper__image'
              loading='lazy'
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/cambio-dobra5-360/image.webp'
              alt='Homem deitado usando celular'
            />
          )}
          <div className='content_wrapper__texts_wrapper'>
            <h2 className='content_wrapper__texts_wrapper__title'>
              Compre dólar com cotação comercial
            </h2>
            <p className='content_wrapper__texts_wrapper__description'>
              Economize ainda mais na compra de dólares com a conta global 100% gratuita do Inter!
            </p>
            <ul className='content_wrapper__texts_wrapper__advantages'>
              <li className='content_wrapper__texts_wrapper__advantages__benefit'>
                Menos IOF (1,1%)
              </li>
              <li className='content_wrapper__texts_wrapper__advantages__benefit'>
                Spread reduzido
              </li>
              <li className='content_wrapper__texts_wrapper__advantages__benefit'>
                Cotação comercial, mais barata que a de turismo
              </li>
              <li className='content_wrapper__texts_wrapper__advantages__benefit'>
                Compre dólares com poucos cliques e em tempo real
              </li>
            </ul>
            <Button
              as='a'
              href='https://intergo.app/b766cf1f'
              className='content_wrapper__texts_wrapper__buy_dollars'
              fullWidth
              onClick={() => sendDatalayerEvent({
                section: 'dobra_05',
                section_name: 'Compre dólar com cotação comercial',
                element_action: 'click button',
                element_name: 'Comprar dólar',
                redirect_url: 'https://intergo.app/b766cf1f',
              })}
            >
              Comprar dólar
            </Button>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default CompreDolar
