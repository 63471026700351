import React, { useEffect, useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

import pageContext from './pageContext.json'
import HeroCambioNoInter from './sections/HeroCambioNoInter/_index'
import CambioComCondicoesExclusivas from './sections/CambioComCondicoesExclusivas/_index'
import EnviarRemessasInternacionais from './sections/EnviarRemessasInternacionais/_index'
import RecebaRemessaComSeguranca from './sections/RecebaRemessasComSeguranca/_index'
import CompreDolar from './sections/CompreDolar/_index'
import ContaGlobalParaUsar from './sections/ContaGlobalParaUsar/_index'
import QuandoUsarOsServicos from './sections/QuandoUsarOsServicos/_index'
import EconomizeComOCambio from './sections/EconomizeComOCambio/_index'
import TudoSobreBeneficios from './sections/TudoSobreBeneficios/_index'
import { Theme, initTheme } from '@interco/inter-ui'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

const Cambio = () => {
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false)
  const [ datalayer, setDatalayer ] = useState<IDataLayerParams>({
    section: '',
    section_name: '',
    element_action: 'click button',
    element_name: '',
  })
  const domReady = useDomReady()

  const modal = domReady && (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      locationToRender={document.body}
    >
      <QrCodeModal
        qrCode='https://central-imagens.bancointer.com.br/images-without-small-versions/qrcode-cambio/image.webp'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o Super App do Inter.'
        title='<span class="d-flex justify-content-center">Acesse sua conta</span>'
        setIsModal={setIsModalOpen}
        isModal={isModalOpen}
        isWebm
        dataLayer={datalayer}
      />
    </Modal>
  )

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <Wrapper>
      {modal}
      <Layout pageContext={pageContext}>
        <HeroCambioNoInter setDatalayer={setDatalayer} setIsModalOpen={setIsModalOpen} />
        <CambioComCondicoesExclusivas />
        <EnviarRemessasInternacionais />
        <RecebaRemessaComSeguranca />
        <CompreDolar />
        <ContaGlobalParaUsar />
        <QuandoUsarOsServicos setDatalayer={setDatalayer} setIsModalOpen={setIsModalOpen} />
        <EconomizeComOCambio />
        <TudoSobreBeneficios />
      </Layout>
    </Wrapper>
  )
}

export default Cambio
