import React from "react"
import * as S from './styles'
import * as Types from './types'

const AccentHeaderCard = ({
  className,
  title,
  titleClass,
  description,
  descriptionClass,
  descriptionBackgroundColor,
}: Types.SplittedCardProps) => {
  function returnBgColorClass (color?: Types.BackgroundOptions) {
    if (color) {
      return `card__description--background-${color}`
    }
    return ''
  }

  return (
    <S.Card className={className}>
      <h3
        className={`card__title ${titleClass}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className={`card__description ${descriptionClass} ${returnBgColorClass(descriptionBackgroundColor)}`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </S.Card>
  )
}

export default AccentHeaderCard
